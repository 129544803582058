@import "../../../scss/main.scss";

$closed-menu-width: 5.6rem;
$opened-menu-width: 21.65rem;
$medium-font-size: 2rem; //16px

@mixin transition-opacity($time-delay, $duration) {
  transition-property: opacity;
  transition-delay: $time-delay;
  transition-duration: $duration;
}

@mixin transition-width($time-delay) {
  transition-property: width;
  transition-duration: $time-delay;
  transition-timing-function: linear;
}

@mixin transition-visibility($time-delay, $duration) {
  transition-property: visibility;
  transition-delay: $time-delay;
  transition-duration: $duration;
}

@mixin flex-column-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.nav-bar {
  position: fixed;
  left: 0;
  top: 0;
  background-color: map-get($colors, "lightgrey");
  width: $closed-menu-width;
  height: 100%;
  overflow: hidden;
  @include flex-column-menu;
  @include transition-width(0.25s);
  &.extendable {
    width: $opened-menu-width;
    ul li:first-child svg {
      transform: translateY(-50%) translateX(-50%) rotate(-180deg);
    }
  }
  ul {
    padding-left: 0rem;
    width: 100%;
    li {
      display: block;
      position: relative;
      color: map-get($colors, "mediumblue");
      height: $closed-menu-width;
      padding: 1.25rem;
      list-style-type: none;
      font-size: $medium-font-size;
      cursor: pointer;
      transition: all 0.5s;
      //vertical-align: top;
      &:first-child {
        &:hover {
          background-color: map-get($colors, "babyblue");
        }
      }
      &.active {
        background-color: map-get($colors, "mediumblue");
        color: map-get($colors, "white");

        span {
          color: map-get($colors, "white");
        }
        a {
          color: map-get($colors, "white");
          pointer-events: none;
        }
        svg * {
          fill: map-get($colors, "white");
        }
      }

      &:not(:first-of-type):not(.active) {
        svg path {
          fill: map-get($colors, "mediumblue");
        }
      }

      svg {
        position: absolute;
        display: block;
        top: calc(#{$closed-menu-width} / 2);
        left: calc(#{$closed-menu-width} / 2);
        transform: translateY(-50%) translateX(-50%);
        transition: all 0.5s;
        &.icon-arrow {
          width: 16px;
        }
        &.fa-user {
          width: 16px;
        }
        &.fa-i-cursor {
          width: 8px;
        }
        &.fa-lightbulb {
          width: 12px;
        }
        &.fa-ticket-alt {
          width: 16px;
        }
        + span {
          @include t-body;
          color: map-get($colors, "mediumblue");
          display: block;
          position: absolute;
          left: $closed-menu-width;
          top: calc((#{$closed-menu-width} / 2) - 2px);
          transform: translateY(-50%);
          font-weight: normal;
        }
      }

      a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        transition: all 0.5s;
        &:hover {
          background-color: map-get($colors, "babyblue");
        }
        span {
          @include transition-opacity(0s, 0.1s);
          opacity: 0;
          //visibility: hidden;
          white-space: nowrap;
          pointer-events: none;
          cursor: default;
          &.show {
            //display: inline-block;
            opacity: 1;
            @include transition-opacity(0.25s, 0.1s);
          }
        }
      }
    }
  }
}

.link-hide-underline {
  text-decoration: none;
}

.flex-start-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.breadcrumb-wrapper {
  width: 100%;
  // max-width: 875px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  line-height: 1rem;
  margin-top: 3rem;
  margin-bottom: 7rem;

  @media #{map-get($media, "mw600")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  &.single {
    justify-content: flex-end;
  }

  .breadcrumb {
    a {
      text-decoration: none;
      color: map-get($colors, "lightblue");
    }
    span {
      &#final-crumb {
        padding-left: 0.5rem;
      }
    }
  }

  .logged-in-text {
    + * {
      @include t-body-sm;
    }

    a {
      text-decoration: none;
      transition: color 0.3s ease;
      color: map-get($colors, "mediumblue");
    }
    a:hover {
      color: map-get($colors, "darkblue");
    }
  }
}
