.chart-row {
  h2 {
    margin-bottom: 20px;
    font-size: 24px;

    span {
      font-size: 40px;
    }
  }
}

.dashboard-wrapper {
  max-width: 1300px;
  margin: 0 auto;
  padding-bottom: 60px;
}

.dashboard-layout {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 65px;
}

.dashboard-panel {
  margin-bottom: 50px;
}

.filter-label {
  margin-right: 10px;
}

.dashboard-panel {
  display: flex;
  align-items: center;
}

.dashboard-coupons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 20px;
}

.dashboard-coupon {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 20px;
  border: 1px solid #ef9f9f;
  cursor: pointer;

  &:hover {
    span {
      opacity: 0.5;
    }
  }

  span {
    margin-left: 3px;
    font-size: 10px;
    color: #ef9f9f;
  }

  & + .dashboard-coupon {
    margin-left: 10px;
  }
}
