@import "~bootstrap/scss/bootstrap";
@use "sass:map";
@import "./main.scss";
// @import "./sicker-sheet.scss";

body,
html {
  font-family: $type-body;
  font-size: 10px;
  * {
    @include t-body-sm;
  }
}

$font-main: $type-body;

$theme-colors: (
  "primary": map-get($colors, "mediumblue"),
  "secondary": map-get($colors, "darkblue"),
);

$font-family-sans-serif: $font-main;
$font-family-monospace: $font-main;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-bold: 700;

$border-radius: 0.5rem;

$input-placeholder-color: map-get($colors, "mediumgrey");
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 2rem;
$input-font-family: $font-main;
$input-font-size: 1.4rem;
$input-color: map-get($colors, "black");

$font-size-base: 1.4rem;
$input-btn-font-size: 1.4rem;

$grid-gutter-width: 4rem;

$pagination-border-width: 0;
$pagination-color: map-get($colors, "mediumblue");

$pagination-hover-bg: none;

$pagination-active-bg: none;
$pagination-active-color: map-get($colors, "mediumblue");

form {
  position: relative;
  width: 100%;
  float: left;
  margin-top: 9rem;
  margin-bottom: 9rem;
  &.purchase-payment {
    margin-top: 6rem;
  }
  > .row:not(:first-child) {
    margin-top: 4rem;
  }
  .form-message {
    @include sm-paragraph;
    margin-top: 5rem;
    &.above-button {
      margin-top: 0;
    }
  }
  .field-description {
    @include t-body-sm;
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 4rem;
  }
}

.form-group {
  @include t-body-sm;
  &:not(:last-of-type) {
    margin-bottom: 4rem;
  }
  > .form-label {
    display: block;
    position: absolute;
    text-indent: -9999px;
  }
  input[type="text"],
  textarea,
  input[type="password"],
  input[type="number"] {
    &.form-control {
      @include t-body-sm;
      display: block;
      border: 0px;
      padding: 1rem;
      border-bottom: solid 2px map-get($colors, "mediumblue");
      background-color: transparent;
      border-radius: 0;
      &::placeholder {
        color: map-get($colors, "darkgrey");
        transition: all 0.25s;
      }
      &:hover {
        border-color: map-get($colors, "darkgrey");
        &::placeholder {
          color: map-get($colors, "mediumblue");
        }
      }
      &:active,
      &:focus {
        border-color: map-get($colors, "darkgrey");
      }
    }
  }
  .error-message {
    color: map-get($colors, "red");
    @include t-body-xs;
    margin-top: 0.5rem;
  }
}
