@import "../../../scss//sicker-sheet.scss";
@import "../../../scss//main.scss";

h2 {
  margin-bottom: 30px;
}

.start-page-main-wrapper-div {
  width: 100%;
  // margin-left: 7rem;
  .start-page-flex-wrapper {
    width: 62rem;
    // min-width: 56rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .body-wrapper {
      margin-top: 12rem;

      h1 {
        &.title {
          @include t-admin-header;
        }
      }
    }
  }
}

.start-question {
  margin: 3rem 0;
  span {
    @include font-set-up(200, 2rem, "mediumblue");
  }
}

.start-page-select-row-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 7.2rem;
  transition: all 0.5s;
  &:hover {
    background-color: map-get($colors, "babyblue");
  }

  .arrow-div {
    margin-right: 2rem;
  }

  .svg-inline--fa {
    position: relative;

    * {
      fill: map-get($colors, "mediumblue");
    }
    &.fa-user {
      width: 16px;
      left: 10px;
    }

    &.fa-i-cursor {
      width: 8px;
      left: 14px;
    }
    &.fa-lightbulb {
      width: 12px;
      left: 12px;
    }
    &.fa-ticket-alt {
      width: 16px;
      left: 10px;
    }
  }

  span {
    &#icon-label {
      //margin-left: 4.5rem;
      @include font-set-up(200, 2rem, "mediumblue");
    }
  }
}

.link-no-underline {
  display: block;
  text-decoration: none;

  & + .link-no-underline {
    border-top: 1px solid #e2e3e5;
  }
}
