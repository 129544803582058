@import "../../../scss/main.scss";
@import "../user-management/user-management-page.scss";

@import "../../../scss/mixins";

$outer-um-div-max-width: 857px;
$light-font-weight: 200;
$sm-gap: 0.625rem;
$font-15: 0.9375rem;

@mixin font-set-up($weight, $size, $color) {
  font-weight: $weight;
  font-family: $type-body;
  color: map-get($colors, $color);
  font-size: $size;
}
.main-content-management-wrapper {
  margin-left: $mobile-left-margin;
  margin-right: 2rem;
  @media #{map-get($media, "mw600")} {
    margin-top: 1.875rem;
    margin-left: 15rem;
    margin-right: 1.5rem;
  }
}

.content-management-outer {
  width: 100%;
  max-width: $outer-um-div-max-width;
  margin: auto;
  h1 {
    @include t-admin-header;
    margin-top: 3rem;
    @media #{map-get($media, "mw600")} {
      margin-top: 7rem;
    }
  }
}

$edit-width: 5.5rem;

.content-management-container-div {
  width: 100%;
  max-width: 875px;
  padding: 0;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  :nth-child(odd) {
    @media #{map-get($media, "mw600")} {
      padding-right: 1.5rem;
    }
  }
  :nth-child(even) {
    @media #{map-get($media, "mw600")} {
      padding-left: 1.5rem;
    }
  }
  .content-management-row {
    width: 100%;
    margin-top: 3rem;
    @media #{map-get($media, "mw600")} {
      width: 50%;
      margin-top: 6rem;
    }

    table,
    tr,
    th,
    td {
      @include t-body;
      color: map-get($colors, "darkgrey");
    }

    table {
      margin: 0;
      width: 100%;
      th {
        @include t-body-sm;
        color: map-get($colors, "darkblue");
        font-weight: 700;
        padding-bottom: 1rem;
        padding-left: 0;
      }
      tbody {
        tr {
          border-bottom: solid 1px map-get($colors, "mediumgrey");
          td {
            @include t-body-sm;
            height: 3.5rem;
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 0;
            &:first-child {
              padding-left: 2rem;
              width: calc(100% - #{$edit-width});
            }
            &:last-child {
              text-align: right;
              width: $edit-width;
            }
            a {
              margin-right: 2rem;
              text-decoration: none;
              //transition: all 0.5s;
              display: block;
              position: relative;
              z-index: 1;
              width: $edit-width;
              height: 100%;
              padding-top: 0.4rem;
              color: map-get($colors, "lightblue");
              &:before {
                content: "";
                display: block;
                position: absolute;
                background-color: map-get($colors, "babyblue");
                width: $edit-width;
                top: 50%;
                right: calc(-1.5rem + 1px);
                z-index: -1;
                height: 100%;
                transform: translateY(-50%);
                opacity: 0;
                pointer-events: none;
                //transition: all 0.5s;
                :hover {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

.single-content-wrapper {
  margin-left: 4rem;
  margin-right: 1rem;
  @media #{map-get($media, "mw600")} {
    margin-top: 1.875rem;
    margin-left: 23rem;
    margin-right: 1.5rem;
  }
}

.single-content-outer {
  width: 100%;
  max-width: 900px;
  margin: auto;

  .single-content-flex {
    width: 110%;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media #{map-get($media, "mw600")} {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    div {
      &#single-content-wide {
        order: 2;
        width: 75%;
        @media #{map-get($media, "mw600")} {
          width: 65%;
          order: 1;
        }
        form {
          &#single-content {
            margin-top: 0;
            margin-bottom: 0;
            width: 100%;
            img {
              @include box-shadow-2();
              width: 100%;
            }
            label {
              @include t-body-sm;
              color: map-get($colors, "darkblue");
              font-weight: 700;
              padding-bottom: 1rem;
              padding-left: 0;
              margin-top: 1rem;
            }
            textarea {
              border: 0px;
              display: block;
              width: 100%;
              //border-bottom: solid 2px map-get($colors, "lightblue");
              background-color: transparent;
              overflow: scroll;
              color: black;
              padding: 10px;
              margin: 2rem 0;
              ::placeholder {
                padding-left: 10px;
                opacity: 0.8;
              }
            }
            #right-align {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-end;
              height: 6rem;
              span {
                padding-bottom: 1rem;
                padding-right: 1rem;
              }
            }
            .btn-primary {
              position: relative;
              right: 0;
              margin-top: 1rem;
              @media #{map-get($media, "mw600")} {
                margin-top: 2rem;
              }
            }
            span {
              &#button-replace {
                display: block;
                position: relative;
                right: 0;
                margin-top: 2rem;
                @include font-set-up($light-font-weight, 2rem, "mediumblue");
              }
              &#empty {
                display: block;
                margin: 5rem 0;
              }
            }
            .rdw-editor-wrapper {
              margin-bottom: 3rem;
            }
            .DraftEditor-root {
              span {
                span {
                  font-weight: inherit;
                  color: black;
                }
              }
            }
          }
        }
        .left-align {
          position: relative;
          left: 0;
          display: inline-block;
          margin-bottom: 5rem;
          margin-top: 1rem;
          a {
            text-decoration: none;
          }
          @media #{map-get($media, "mw600")} {
            margin-top: 0;
          }
          span {
            @include font-set-up(200, 1.5rem, "lightblue");
            padding-left: 1rem;
            &.icon-arrow {
              svg {
                transform: rotate(180deg);
                position: relative;
                bottom: 0.15rem;
              }
            }
          }
        }
      }
      &#single-content-narrow {
        order: 1;
        margin-bottom: 2rem;
        @media #{map-get($media, "mw600")} {
          order: 2;
          margin-left: 5rem;
          margin-bottom: 5rem;
        }
        #editable-color {
          display: inline-block;
          background-color: map-get($colors, "yellow");
          width: 2rem;
          height: 1rem;
        }
      }
    }
  }
  .container {
    width: 110%;
    margin-top: 4rem;
  }
  .col {
    &#single-content-wide {
      width: (7/9) * 100%;
    }
    &#single-content-narrow {
      div {
        position: relative;
        margin-left: 2rem;
        top: 30%;
        #editable-color {
          display: inline-block;
          background-color: map-get($colors, "yellow");
          width: 2rem;
          height: 1rem;
        }
      }
    }
  }
}

.toolbarClassName {
  background: map-get($colors, "lightgrey") !important;
  padding-top: 1rem !important;
  div {
    border: none;
    background-color: map-get($colors, "lightgrey");
    opacity: 0.7;
  }
}

.wrapperClassName {
  @include box-shadow-2();
  margin-top: 2rem;
}

.editorClassName {
  padding-left: 1rem;
  height: 16rem !important;
}

//should move to user management.scss
//single user page style

.main-single-user-wrapper {
  margin-top: 1.875rem;
  margin-left: $mobile-left-margin;
  margin-right: 1.5rem;
  margin-bottom: $bottom-spacing;
  @media #{map-get($media, "mw600")} {
    margin-left: 15rem;
  }
}

.single-user-outer {
  width: 100%;
  max-width: 88rem;
  margin: auto;

  h1 {
    @include t-admin-header;
    margin-top: 7rem;
  }
  span {
    &.page-title {
      display: block;
      margin-top: 8rem;
      @include font-set-up($light-font-weight, 2rem, "darkgrey");
      span {
        @include font-set-up($light-font-weight, 2rem, "navyblue");
      }
    }
  }
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  &.basic {
    max-width: 28rem;
  }
  &.billing {
    max-width: 56rem;
  }
  .header {
    height: 3rem;
    width: 100%;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    span {
      display: inline-block;
      @include font-set-up($light-font-weight, 2rem, "mediumblue");
      &.edit {
        display: inline-block;
        @include font-set-up($light-font-weight, 1.5rem, "lightblue");
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  hr {
    height: 2px;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    width: 100%;
    display: block;
    background-color: map-get($colors, "darkgrey");
  }
  span {
    &#label {
      display: block;
      margin-top: 1rem;
      padding: 0;
      @include font-set-up(400, 1.5rem, "navyblue");
    }
    @include font-set-up(200, 1.5rem, "darkgrey");
    padding: 1rem 0;
  }
}

.add-license-wrapper {
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  @include clear();
  margin-right: 1rem;
  margin-top: 1.5rem;
  width: 100%;
  span {
    &#add-license {
      @include font-set-up($light-font-weight, 1.5rem, "mediumblue");
      cursor: pointer;
      padding-right: 2rem;
    }
    &.edit-value {
      float: right;
    }
  }
  button {
    color: map-get($colors, "mediumblue");
    padding: 0.8 * $sm-gap 2 * $sm-gap $sm-gap 2 * $sm-gap;
    background-color: map-get($colors, "white");
    border: 2px solid map-get($colors, "mediumblue");
    margin-left: $sm-gap;
    line-height: 1.4rem;
    @include font-set-up(200, 1.4rem, "lightblue");
    transition: all 0.5s;
    &:hover {
      background-color: map-get($colors, "mediumblue");
      color: map-get($colors, "white");
    }
    &.edit-spacing {
    }
    &#values-delete {
      float: left;
      border: 2px solid map-get($colors, "red");
      color: map-get($colors, "red");
    }
  }
}

table {
  &#licenses,
  &#orders {
    width: 100%;
    margin-top: 7rem;
    td:first-child {
      padding-left: 0;
    }
    thead {
      td {
        text-align: left;
        &#name {
          @include font-set-up($light-font-weight, 2rem, "mediumblue");
          width: 38.5%;
          padding-left: 0;
        }
        @include font-set-up($light-font-weight, 1.5rem, "darkgrey");
        span {
          @include font-set-up($light-font-weight, 1rem, "darkgrey");
        }
        &#keys {
          min-width: 15rem;
        }
      }
    }
    tbody {
      td {
        a {
          span {
            &:hover {
              background-color: map-get($colors, "babyblue");
            }
            
            &#order, &#pdf {
              display: inline-block;
              margin-right: 1rem;
              text-decoration: none;
              @include font-set-up($light-font-weight, 1.5rem, "mediumblue");
              cursor: pointer;
            }
          }
        }
        span {
          &#keys {
            @include font-set-up($light-font-weight, 1.5rem, "mediumblue");
            cursor: pointer;
            padding-right: 2rem;
          }
        }
        &:nth-child(2) {
          width: 10rem;
        }
      }
    }
  }
}

form {
  &#license-table {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding-top: 0.2rem;

    .form-control {
      &#quantity-keys {
        display: inline-block;
        width: 55%;
        margin-right: 10%;
        padding-top: 5px;
        &::placeholder {
          text-align: center;
        }
      }
    }
    .btn-primary {
      height: 3.4rem;
      width: 45%;
      margin-top: 0;
      padding: 0.5rem 1rem 0.75rem 1rem;
      line-height: 1.4rem;
      @include font-set-up($light-font-weight, 1.4rem, "mediumblue");
    }
  }
  &#user-basic-info,
  &#user-billing-info {
    margin: 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @media #{map-get($media, "mw600")} {
      margin: 1rem 0;
    }

    .form-group {
      margin-bottom: 1rem;
      width: 100%;
      &.short {
        width: 46%;

        display: inline-block;
      }
      &.xs {
        width: 20%;
        display: inline-block;
      }
      > .form-label {
        &.info-label {
          display: block;
          text-indent: 0;
          position: static;
          margin-bottom: 0;
          @include font-set-up(bold, 1.5rem, "navyblue");
        }
      }
      > .form-control {
        padding-top: 0.5rem;
        padding-left: 0;
      }

      input[type="text"],
      input[type="number"] {
        transition: border-color 0.5s linear;
        &.read-only {
          border-color: rgba(0, 0, 0, 0);
          pointer-events: none;
        }
      }
    }
    span {
      &#cancel {
        position: relative;
        right: 2rem;
        @include font-set-up(200, 1.5rem, "red");
        padding-top: 1rem;
        line-height: 1.5rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
    button {
      &.btn-primary {
        margin-top: 0rem;
        height: 3.4rem;
        padding: 0rem 2rem;
        font-size: 1.4rem;
      }
    }
  }
}
