@import "../../../scss/main.scss";
// @import "~bootstrap/scss/bootstrap";
@import "../../../scss/sicker-sheet.scss";

$sm-gap: 1rem;

@mixin font-set-up($weight, $size, $color) {
  font-weight: $weight;
  font-family: $type-body;
  color: map-get($colors, $color);
  font-size: $size;
}

.login-main-wrapper-div,
.forgot-password-wrapper-div {
  width: 70%;
  margin: auto;
  @media #{map-get($media, "mw600")} {
    width: 37%;
  }
  .login-flex-wrapper,
  .forgot-password-flex-wrapper {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .form-centered-div {
    margin-top: change-base(9.75rem);
  }
  .login-center-div {
    margin-top: 20rem;
  }
  span {
    &.login-lapin-header {
      @include font-set-up(200, change-base(0.9375rem), "darkgrey");
    }
    &.login-page-title,
    &.forgot-password-page-title {
      @include t-admin-header();
    }
  }
}
.request-password-wrapper-div {
  width: 70%;
  margin: auto;
  @media #{map-get($media, "mw600")} {
    width: 37%;
  }
  .request-password-flex-wrapper {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    span {
      &.request-password-page-title {
        @include t-admin-header();
      }
    }
  }
}

.form-group {
  //@include t-body-sm;
  &:not(:last-of-type) {
    margin-bottom: 4rem;
  }
  > .form-label {
    display: block;
    position: absolute;
    text-indent: -9999px;
  }
  input[type="text"],
  input[type="password"],
  input[type="number"] {
    &.form-control {
      //@include t-body-sm;
      display: block;
      border: 0px;
      padding: 0 0 $sm-gap/2 0;
      line-height: 0.9375rem;
      border-bottom: solid 2px map-get($colors, "lightblue");
      background-color: transparent;
      border-radius: 0;
      &::placeholder {
        color: map-get($colors, "darkgrey");
        transition: all 0.25s;
      }
      &:hover {
        border-color: map-get($colors, "darkgrey");
        &::placeholder {
          color: map-get($colors, "mediumblue");
        }
      }
      &:active,
      &:focus {
        border-color: map-get($colors, "darkgrey");
      }
    }
  }
  .error-message {
    color: map-get($colors, "red");
    //@include t-body-xs;
    margin-top: 0.5rem;
  }
}

form {
  margin-top: change-base(3.25rem);
  .login-button-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: change-base(3.15625rem);
  }

  a {
    @include font-set-up(200, change-base(1rem), "lightblue");
    text-decoration: none;
  }

  .btn-primary {
    //@include t-body;
    background-color: transparent;
    border: solid 2px map-get($colors, "mediumblue");
    border-radius: 0px;
    //margin-top: 3.15625rem;
    padding: 1rem 1.75rem 1rem 1.75rem;
    float: right;
    line-height: change-base(1.25rem);
    @include font-set-up(200, change-base(1.25rem), "mediumblue");
    &:hover {
      background-color: map-get($colors, "mediumblue");
      border-color: map-get($colors, "mediumblue");
      color: map-get($colors, "white");
      .icon svg g path {
        stroke: map-get($colors, "white");
        transition: all 0.25s;
      }
    }
    .icon {
      margin-left: $sm-gap;
      position: relative;
      bottom: 0.1rem;
    }
    .icon svg g path {
      stroke: map-get($colors, "mediumblue");
      transition: all 0.25s;
    }
  }
}
