@import "../../../scss/main.scss";

$outer-um-div-max-width: 857px;
$light-font-weight: 200;
$sm-gap: 1rem;

@mixin font-set-up($weight, $size, $color) {
  font-weight: $weight;
  font-family: $type-body;
  color: map-get($colors, $color);
  font-size: $size;
}

.title-row-flex-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media #{map-get($media, "mw600")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  h1.title {
    @include t-admin-header;
  }
  .search-div {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    margin-top: 0.5rem;
    @media #{map-get($media, "mw600")} {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
    input[type="text"] {
      border: 1px solid #ced4da;
      height: 3.4rem;
      padding: 0 0 0 1.3rem;

      @media #{map-get($media, "mw600")} {
        margin: 0;
      }
      &::placeholder {
        //padding: $sm-gap 0 $sm-gap 0.8125rem;
        @include font-set-up($light-font-weight, 1.6rem, "mediumgrey");
      }
    }
    button {
      @include t-body-sm;
      height: 3.4rem;
      color: map-get($colors, "mediumblue");
      padding: 0.8 * $sm-gap 2 * $sm-gap $sm-gap 2 * $sm-gap;
      background-color: map-get($colors, "white");
      border: 2px solid map-get($colors, "mediumblue");
      margin-left: $sm-gap;
      line-height: 1.4rem;
      @include font-set-up(200, 1.4rem, "lightblue");
      transition: all 0.5s;
      &:hover {
        background-color: map-get($colors, "mediumblue");
        color: map-get($colors, "white");
      }
    }
    @media #{map-get($media, "mw600")} {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.user-management-form {
  margin: 0;
  float: none;
}

$edit-width: 5.5rem;

table {
  width: 100%;
  margin-top: 4.4rem;

  tr {
    border-bottom: solid 1px map-get($colors, "lightgrey");
    width: 100%;
  }

  thead tr:first-child,
  tbody tr:last-child {
    border-color: map-get($colors, "mediumgrey");
  }

  td,
  th {
    height: 3.5rem;
    padding: 1rem 1rem 1rem 0;
    color: map-get($colors, "darkgrey");
    font-weight: 200;

    &:first-child {
      padding: 0 0 0.5rem 1rem;
    }

    @media #{map-get($media, "mw600")} {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &#name {
      width: 22.5rem;
    }

    &:last-child {
      text-align: right;

      a {
        margin-right: 2 * $sm-gap;
        text-decoration: none;
        transition: all 0.5s;
        display: block;
        position: relative;
        z-index: 1;
        height: 100%;
        text-align: center;

        &:before {
          content: "";
          display: block;
          position: absolute;
          background-color: map-get($colors, "babyblue");
          width: $edit-width;
          top: 50%;
          right: 0;
          z-index: -1;
          height: 100%;
          transform: translateY(-50%);
          opacity: 0;
          pointer-events: none;
          transition: all 0.5s;
        }

        * {
          color: map-get($colors, "mediumblue");
        }

        span {
          transition: background-color 0.2s;
          &:hover {
            background-color: map-get($colors, "babyblue");
          }
        }
      }
    }
  }
}

.pagination-flex-wrapper {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5rem;
  //@include t-body-sm;
  span {
    &.pagination-overview {
      @include font-set-up($light-font-weight, 1.5rem, "darkgrey");
      line-height: 1.5rem;
      margin-right: $sm-gap;
      @media #{map-get($media, "mw600")} {
        margin-right: 2 * $sm-gap;
      }
    }
  }
  .pagination-div {
    span {
      @include font-set-up(200, change-base(0.9375rem), "mediumblue");

      &.pagination-arrow-left {
        color: map-get($colors, "lightblue");
        margin: 0 2rem 0 1rem;

        font-size: 2.4rem;
        &.active {
          cursor: pointer;
          color: map-get($colors, "mediumblue");
        }
      }
      &.pagination-arrow-right {
        color: map-get($colors, "lightblue");
        margin: 0 1rem 0 2rem;

        font-size: 2.4rem;
        &.active {
          cursor: pointer;
          color: map-get($colors, "mediumblue");
        }
      }
    }
    // margin-right: 15.125rem;
  }
}

.single-user-upper-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 3rem;
  @media #{map-get($media, "mw600")} {
    margin-top: 6rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.change-password-wrapper {
  .add-license-wrapper {
    > span {
      display: block;
      &.edit-value {
        float: none;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &.no-float {
      float: none;
      > div {
        float: none;
      }
    }
    .float-right {
      float: none;
    }
  }
}
.float-right {
  float: right;
}

#single-user-group-functionalities {
  margin-left: 10rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  > div {
    margin: 2rem 0;
  }
}

.couponUsedBy_row {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  & + & {
    margin-top: 4px;
  }
}

.couponUsedBy_column {
  display: flex;
  gap: 8px;
}

.couponUsedBy_email {
  max-width: 135px;
}

.couponUsedBy_name {
  max-width: 130px;
}

.couponUsedBy_name,
.couponUsedBy_email {
  overflow: hidden;
  text-overflow: ellipsis;
}
