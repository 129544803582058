@import "./sicker-sheet.scss";

//BODY

@mixin t-body {
  font-weight: normal;
  font-size: 2rem;
  line-height: 3rem;
}

@mixin t-body-sm {
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

@mixin t-body-xs {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin sm-paragraph {
  @include t-body-sm;
  width: 100%;
  position: relative;
  float: left;
  color: map-get($colors, "darkgrey");
  a {
    text-decoration: none;
    color: map-get($colors, "mediumblue");
    transition: all 0.25s;
    &:hover,
    &:focus {
      color: map-get($colors, "darkblue");
    }
  }
}

@mixin font-set-up($weight, $size, $color) {
  font-weight: $weight;
  font-family: $type-body;
  color: map-get($colors, $color);
  font-size: $size;
}

//HEADERS

@mixin t-header-xxl {
  font-weight: normal;
  font-size: 8rem;
  line-height: 10rem;
}

@mixin t-header-xl {
  font-weight: normal;
  font-size: 6rem;
  line-height: 8rem;
}

@mixin t-header-l {
  font-weight: normal;
  font-size: 4rem;
  line-height: 6rem;
}

@mixin t-header-m {
  font-weight: normal;
  font-size: 3rem;
  line-height: 5rem;
}

@mixin t-header-sm {
  font-weight: bold;
  font-size: 2rem;
  line-height: 3rem;
}

@mixin t-admin-header {
  font-weight: 200;
  font-size: 2.6rem;
  line-height: 3.64rem;
  color: map-get($colors, "darkblue");
  margin: 0;
}

//BUTTON
@mixin button-primary {
  @include t-body;
  background-color: transparent;
  border: solid 2px map-get($colors, "mediumblue");
  border-radius: 0px;
  color: map-get($colors, "mediumblue");
  padding: 0.7rem 1.5rem 1rem;
  float: right;
  margin-top: 5rem;
  transition: all 0.5s;
  .icon {
    margin-left: 1rem;
  }
  .icon svg g path {
    stroke: map-get($colors, "mediumblue");
    transition: all 0.25s;
  }
  &:hover {
    background-color: map-get($colors, "mediumblue");
    border-color: map-get($colors, "mediumblue");
    color: map-get($colors, "lightgrey");
    .icon svg g path {
      stroke: map-get($colors, "lightgrey");
      transition: all 0.25s;
    }
  }
  &.white {
    border-color: map-get($colors, "lightgrey");
    color: map-get($colors, "lightgrey");
    .icon svg g path {
      stroke: map-get($colors, "lightgrey");
    }
    &:hover {
      background-color: map-get($colors, "lightgrey");
      border-color: map-get($colors, "lightgrey");
      color: map-get($colors, "mediumblue");
      .icon svg g path {
        stroke: map-get($colors, "mediumblue");
        transition: all 0.25s;
      }
    }
  }
}

@mixin clear {
  display: block;
  height: 1%;
  &::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: "";
    clear: both;
    height: 0;
  }
}

@mixin button-standard {
  color: map-get($colors, "mediumblue");
  padding: 0.8 * $sm-gap 2 * $sm-gap $sm-gap 2 * $sm-gap;
  background-color: map-get($colors, "white");
  border: 2px solid map-get($colors, "mediumblue");
  margin-left: $sm-gap;
  line-height: 1.4rem;
  @include font-set-up(200, 1.4rem, "lightblue");
  transition: all 0.5s;
  &:hover {
    background-color: map-get($colors, "mediumblue");
    color: map-get($colors, "white");
  }
}
